import axios from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { Box, Text } from 'rebass';
import { FUNCTIONS_URL } from '../App';
import AgentBox from './AgentBox';
import ReactPullToRefresh from 'react-pull-to-refresh';

const apiGetContacts = async (args: any) => {
  console.log(args);
  const ownerId = Array.isArray(args) ? args[0] : args.ownerId;
  try {
    const res = await axios(`${FUNCTIONS_URL}/contacts/${ownerId ? `${ownerId}` : 'unassigned'}`);
    return res.data;
  } catch {}
};

const AssignationList = ({ owners, ownerId }: any): ReactElement => {
  const getContacts = useAsync({ promiseFn: apiGetContacts, deferFn: apiGetContacts, ownerId, watch: ownerId });
  const [contacts, setContacts] = useState<any[]>([]);

  const handleRefresh = (resolve: any, reject: any) => {
    try {
      getContacts.run(ownerId);
      resolve();
    } catch {
      reject();
    }
  };

  useEffect(() => {
    if (getContacts.data) {
      setContacts(getContacts.data.results);
    }
  }, [getContacts.data]);

  const showAgentBox = owners?.length > 0 && contacts?.length > 0 && !getContacts.isPending;

  return (
    <ReactPullToRefresh onRefresh={handleRefresh}>
      <Box>
        <Text p={3} color="primary" textAlign="center" fontWeight="bold">
          {getContacts.isPending ? `Cargando...` : `${contacts.length} contactos disponibles.`}
        </Text>
      </Box>
      {getContacts.error && (
        <Box width={1} px={5} pt={3} color={'#ac0000'}>
          {getContacts.error}
        </Box>
      )}
      {showAgentBox && (
        <Box width={1} p={3} height={`calc(100vh - 190px)`} pb={7} style={{ overflow: 'auto' }}>
          {owners.map(
            (owner: any) =>
              parseInt(ownerId) !== owner.ownerId && (
                <AgentBox key={owner.ownerId} owner={owner} contacts={contacts} setContacts={setContacts} />
              ),
          )}
        </Box>
      )}
    </ReactPullToRefresh>
  );
};

export default AssignationList;
