import React, { ReactElement } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#07b6a5',
      main: '#049889',
      dark: '#00685e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#d8b97e',
      main: '#e8cc5f',
      dark: '#a58a56',
      contrastText: '#000',
    },
  },
});

export default function Theme(props: any): ReactElement {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
