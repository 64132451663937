import axios from 'axios';
import React, { ReactElement, useRef, useState } from 'react';
import { Box, Flex } from 'rebass';
import { FUNCTIONS_URL } from '../App';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { Button, Select } from '@material-ui/core';
import { useAsync } from 'react-async';

const apiAssignOwner = async (args: any) => {
  return axios.post(`${FUNCTIONS_URL}/owners/assign`, { inputs: args[0] });
};

const AgentBox = ({ owner, contacts, setContacts }: any): ReactElement => {
  const [num, setNum] = useState<number>(0);
  const { error, run, isPending } = useAsync({
    deferFn: apiAssignOwner,
    onResolve: () => {
      setNum(0);
      setContacts(contacts.slice());
    },
  });
  const ref = useRef();
  const name = `${owner.firstName} ${owner.lastName}`;

  const onChange = (e: any) => {
    setNum(parseInt(e.target.value));
  };

  const isValidNum = (num: any) => num > 0;

  const assignOwner = async () => {
    const newArray = contacts.splice(contacts.length > 0 ? 0 : -1, num);
    const inputs = newArray.map((contact: any) => {
      return {
        id: contact.id,
        properties: {
          hubspot_owner_id: owner.ownerId,
        },
      };
    });

    try {
      await run(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  useOnClickOutside(ref, () => setNum(0));

  const maxContacts = Array.from(Array((contacts.length <= 10 ? contacts.length : 10) + 1).keys());

  return (
    <Box py={2} sx={{ borderBottom: '1px solid', borderColor: 'text' }} key={owner.ownerId} ref={ref}>
      <Flex alignItems="center" justifyContent="space-between">
        <Box overflow="hidden" marginRight={2} width={2 / 3}>
          <span data-testid={owner.ownerId}>{owner.firstName ? name : owner.email}</span>
        </Box>
        <Box width={1 / 3} px={2}>
          <Select native fullWidth placeholder="Max. 10 contactos" onChange={onChange} value={num}>
            {maxContacts.map((quantity) => (
              <option key={quantity} value={quantity}>
                {quantity} contactos
              </option>
            ))}
          </Select>
        </Box>
      </Flex>
      {isValidNum(num) && (
        <Box mt={3}>
          <Button onClick={assignOwner} disabled={isPending} color="primary" variant="contained" fullWidth>
            {isPending ? `Asignando...` : `Asignar ${num} contactos`}
          </Button>
        </Box>
      )}
      {error && (
        <Box width={1} px={5} pt={3} color={'#ac0000'}>
          {error}
        </Box>
      )}
    </Box>
  );
};

export default AgentBox;
