import React, { ReactElement, useEffect, useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import Theme from './Theme';
import styled from 'styled-components';
import { FUNCTIONS_URL } from '../App';
import axios from 'axios';
import ContactList from './ContactList';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  hidden: boolean;
}

const TabPanel = styled.div`
  visibility: ${({ hidden }: Props) => (hidden ? 'hidden' : 'visible')};
`;

export default function Main(): ReactElement {
  const [value, setValue] = useState(0);
  const [owners, setOwners] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const ownersRequest = await axios(`${FUNCTIONS_URL}/owners`);
      setOwners(ownersRequest.data);
    })();
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Theme>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Nuevos" {...a11yProps(0)} />
          <Tab label="Reasignar " {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel hidden={value !== 0}>
        <ContactList owners={owners} />
      </TabPanel>
      <TabPanel hidden={value !== 1}>
        <ContactList owners={owners} showSelector={true} />
      </TabPanel>
    </Theme>
  );
}
