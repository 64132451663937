import { Select } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { Box } from 'rebass';
import AssignationList from './AssignationList';

interface Props {
  owners: any[];
  showSelector?: boolean;
}

export default function ContactList({ owners, showSelector = false }: Props): ReactElement {
  const [selectedOwner, setSelectedOwner] = useState<number>();

  const onChange = (e: any) => {
    setSelectedOwner(e.target.value);
  };

  const showList = selectedOwner || !showSelector;

  return (
    <>
      {showSelector && (
        <Box width={1} p={3}>
          <Select native fullWidth placeholder="Seleccionar agente" onChange={onChange} value={selectedOwner}>
            <option value={``}>Seleccionar agente</option>
            {owners.map((owner) => {
              const name = `${owner.firstName} ${owner.lastName}`;
              return (
                <option key={owner.ownerId} value={owner.ownerId}>
                  {owner.firstName ? name : owner.email}
                </option>
              );
            })}
          </Select>
        </Box>
      )}
      {showList && <AssignationList owners={owners} ownerId={selectedOwner} setContacts={() => null} />}
    </>
  );
}
