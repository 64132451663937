import React, { ReactElement, useEffect, useState } from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from './theme';
import { Box, Flex, Image } from 'rebass';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { config } from './firebase.config';
import Login from './components/Login';
import Main from './components/Main';
import Div100vh from 'react-div-100vh';

export const FUNCTIONS_URL = `https://us-central1-rme-assignator.cloudfunctions.net/api`;
// export const FUNCTIONS_URL = 'http://localhost:5001/rme-assignator-dev/us-central1/api';

function App(): ReactElement {
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user') || 'null'));

  useEffect(() => {
    (async () => {
      checkAuth();
    })();
  }, []);

  const checkAuth = async () => {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
      } else {
        localStorage.removeItem('user');
        setUser(null);
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Div100vh>
        <Flex flexDirection="column" justifyContent="flex-start" height="100vh">
          <Box width={1} backgroundColor="gold" p={2}>
            <Flex alignItems={'center'} justifyContent="center">
              <Box width={1} sx={{ textAlign: 'center' }}>
                <Image src={'./logo-rme.png'} />
              </Box>
              {user && (
                <Box
                  sx={{ position: 'absolute', top: 18, right: 20 }}
                  flex={1}
                  color="white"
                  onClick={() => {
                    firebase.auth().signOut();
                  }}
                >
                  Logout
                </Box>
              )}
            </Flex>
          </Box>
          <FirebaseAuthProvider {...config} firebase={firebase}>
            {user ? <Main /> : <Login />}
          </FirebaseAuthProvider>
        </Flex>
      </Div100vh>
    </ThemeProvider>
  );
}

export default App;
