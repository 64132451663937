import { Input, Label } from '@rebass/forms';
import React, { ReactElement, useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import firebase from 'firebase/app';

export default function Login(): ReactElement {
  const [credentials, setCredentials] = useState<any>({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const onLogin = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const onChangeLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Box as="form" onSubmit={onLogin}>
      <Flex flexDirection="column" justifyItems="center">
        <Box width={1} px={5} pt={5}>
          <Label htmlFor="user" fontWeight={'bold'}>
            Usuario
          </Label>
          <Input id="email" name="email" type="email" disabled={loading} onChange={onChangeLogin} />
        </Box>
        <Box width={1} px={5} pt={3}>
          <Label htmlFor="password" fontWeight={'bold'}>
            Password
          </Label>
          <Input
            id="password"
            name="password"
            type="password"
            value={credentials.password}
            disabled={loading}
            onChange={onChangeLogin}
          />
        </Box>
        {error && (
          <Box width={1} px={5} pt={3} color={'#ac0000'}>
            {error}
          </Box>
        )}
        <Box width={1} px={5} pt={3}>
          <Button width={1} type="submit" disabled={loading}>
            Entrar
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}
